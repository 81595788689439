













import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {TimerLog as TimerLogInterface} from '@/interfaces';
import {capitalize} from '@/filters';

@Component
export default class TimerLog extends Vue {
  @Prop({default: []}) log!: TimerLogInterface[];
  capitalize = capitalize;
  headers = [
    {text: 'Created', value: 'created'},
    {text: 'Label', value: 'label'},
    {text: 'Value', value: 'value'},
    {text: 'Difference', value: 'difference'},
  ];
}
