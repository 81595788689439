




















































































































































































































































































































































































import {
  mdiCalendarOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiClockOutline,
  mdiPlayCircleOutline,
  mdiPauseCircleOutline,
  mdiDotsVertical,
  mdiClose,
  mdiTextBoxPlus,
  mdiPlus,
  mdiAccount,
} from '@mdi/js';
import Vue from 'vue';
import ProjectDialog from '@/components/ProjectDialog.vue';
import Component from 'vue-class-component';
import {
  ProjectResource,
  SelectItem,
  Snackbar,
  TaskResource,
  TimeEntryResource,
  TimerAttributes,
  timerTemplates,
} from '@/interfaces';
import {
  authModule,
  clientModule,
  projectModule,
  taskModule,
  timeEntryModule,
  timerModule,
} from '@/store/modules';
import {Prop, Watch} from 'vue-property-decorator';
import {InputValidationRules} from 'vuetify';
import {
  longDate,
  dateFormat,
  timeDisplayToMs,
  msToTimeDisplay,
} from '@/filters';
import TimerLog from '@/components/timers/TimerLog.vue';
import ClientAvatar from '@/components/ClientAvatar.vue';
import {VueShowdown} from 'vue-showdown/dist/vue-showdown.esm';
import LatestTimeEntries from '@/components/time-entries/LatestTimeEntries.vue';
import {Role} from '@/enums';

@Component({
  components: {
    LatestTimeEntries,
    ClientAvatar,
    TimerLog,
    ProjectDialog,
    VueShowdown,
  },
})
export default class TimersTimer extends Vue {
  @Prop() id!: string;
  private dateFormat = dateFormat;
  private dateMenu = false;
  private deleting = false;
  private iconAdd = mdiPlus;
  private iconClose = mdiClose;
  private iconDate = mdiCalendarOutline;
  private iconExpandOpen = mdiChevronUp;
  private iconExpandClose = mdiChevronDown;
  private iconMore = mdiDotsVertical;
  private iconTemplate = mdiTextBoxPlus;
  private iconUser = mdiAccount;
  private iconTime = mdiClockOutline;
  private iconTimerStart = mdiPlayCircleOutline;
  private iconTimerStop = mdiPauseCircleOutline;
  private interval = 0;
  private isCollapsed = false;
  private isDirty = false;
  private logging = false;
  private longDate = longDate;
  private message: string | null = null;
  private projectDialogShown = false;
  private projectId: string | null = null;
  private rulesRequired: InputValidationRules = [
    v => !!v || 'This field is required',
  ];
  private rulesMoreThanZero: InputValidationRules = [
    v => !!v || 'This field is required',
    v => v === true || 'Must be greater than 0',
  ];
  private saving = false;
  private secondaryTabs = 'recentTimeEntries';
  private selectedTemplate: SelectItem | null = null;
  private snackbar: Snackbar = {visible: false, message: ''};
  private tab = 'markdown';
  private templates: SelectItem[] = timerTemplates;
  private time = 0;
  private timeEntryId = 0;
  private timeMenu = false;
  private timeout?: number = undefined;
  private valid = false;
  private roles = Role;

  private users: SelectItem[] = [
    {text: 'John', value: 1},
    {text: 'Leanne', value: 3},
    {text: 'James', value: 6},
  ];

  get clientsCombobox(): SelectItem[] {
    return clientModule.list.map(item => {
      return {
        text: item.attributes.name,
        value: item.attributes.id,
      };
    });
  }

  get role(): Role | null {
    return authModule.role;
  }

  get clientsLoading(): boolean {
    return clientModule.loading;
  }

  get projects(): ProjectResource[] {
    return projectModule.list.filter(
      project =>
        String(project.attributes.clientId) === String(this.timer.clientId)
    );
  }

  get projectsCombobox(): SelectItem[] {
    return this.projects.map(item => {
      return {
        text: item.attributes.name,
        value: item.attributes.id,
      };
    });
  }

  get projectsLoading(): boolean {
    return projectModule.loading;
  }

  get selectedClientName(): string {
    const client = clientModule.find(String(this.timer.clientId));
    return client === undefined ? '' : client.attributes.name;
  }

  get selectedProjectName(): string {
    const project = projectModule.find(String(this.timer.projectId));
    return project === undefined ? '' : project.attributes.name;
  }

  get task(): TaskResource {
    return taskModule.find(String(this.timer.taskId));
  }

  get tasksCombobox(): SelectItem[] {
    return taskModule.list.map(item => {
      return {
        text: item.attributes.name,
        value: item.attributes.id,
      };
    });
  }

  get timeEntry(): TimeEntryResource {
    return timeEntryModule.find(String(this.timeEntryId));
  }

  get timer(): TimerAttributes {
    return timerModule.find(this.id).attributes;
  }

  @Watch('timer.timeDisplay')
  onTimerTimeDisplay() {
    //console.log('onTimerTimeDisplay');
    if (!this.timer.running) {
      //console.log('timer.running');
      this.updateTimeFromTimeDisplay();
    }
  }

  @Watch('timer', {deep: true})
  onTimerUpdate() {
    this.isDirty = true;
  }

  beforeDestroy() {
    clearInterval(this.interval);
    if (this.logging) {
      this.$emit('logged');
    } else if (this.deleting) {
      this.$emit('deleted');
    }
  }

  async created(): Promise<void> {
    //console.log('created');
    //console.log(this.timer.clientId);
    //this.timer.clientId = '37';
    //console.log('time', format(new Date(60000).toUTCString(), 'HH:mm:ss'));
    // get the timer which should already be loaded
    // const timer = timerModule.find(this.id);
    //
    // if (timer === undefined) {
    //   return;
    // }

    // create a copy of the timer
    //this.timer = {...timer};

    this.isDirty = false;

    //this.periodicallyPersistTimer(this.timer.id);
    // this.interval = setInterval(
    //   async () => this.saveTimer(),
    //   120000 + Math.floor(Math.random() * 120000) // 1min + random fraction of minute
    // );
    this.interval = setInterval(
      async () => {
        // is this timer "dirty"?
        if (this.isDirty) {
          // the timer was modified after the timer in the store so we are
          // going to save it
          await this.saveTimer();
          this.isDirty = false;
          return;
        }

        // timer is not "dirty" (has been saved recently or has never been touched)
        await timerModule.loadById(String(this.timer.id));
        this.isDirty = false;
      },
      60 * 60000 + Math.floor(Math.random() * 60 * 60000) // 60*1min + random fraction of minute
    );

    // save when tab loses focus (if dirty)
    window.addEventListener('blur', async () => {
      console.log('tab blur');
      // is this timer "dirty"?
      if (this.isDirty) {
        // the timer was modified after the timer in the store so we are
        // going to save it
        await this.saveTimer();
        this.isDirty = false;
        return;
      }
    });

    // reload timer if not dirty when tab gets focus
    window.addEventListener('focus', async () => {
      console.log('tab focus');
      if (!this.isDirty) {
        // timer is not "dirty" (has been saved recently or has never been touched)
        await timerModule.loadById(String(this.timer.id));
        this.isDirty = false;
      }
    });

    if (this.timer.running) {
      this.startTimer(this.timer.started);
    }
  }

  addMinutes(minutes: number): void {
    //this.timer.duration = minutes;
    this.time += minutes * 60 * 1000;
    this.updateTimeDisplay();
  }

  closeProjectDialog(message: string): void {
    this.projectDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async createTimeEntry(): Promise<void> {
    this.logging = true;
    try {
      // convert time in milliseconds to minutes and convert the float to int using bit invert operator twice

      const minutes = Math.round(this.timer.time / 1000 / 60);
      await timeEntryModule.loadById('new');
      const timeEntry = timeEntryModule.find('new');
      timeEntry.attributes.durationExpense = this.timer.durationExpense;

      timeEntry.attributes.billed = this.timer.billable ? 0 : 2;
      timeEntry.attributes.clientId = this.timer.clientId;
      timeEntry.attributes.date = new Date(this.timer.date);

      if (minutes === 0) {
        timeEntry.attributes.duration = 5;
      } else {
        // round time up to nearest 5 minutes
        timeEntry.attributes.duration = this.roundUpToAny(5, minutes);
      }
      //console.log(minutes, timeEntry.attributes.duration);

      // timeEntry.duration = ~~(this.timer.time / 1000 / 60);
      timeEntry.attributes.description = this.timer.description;
      timeEntry.attributes.projectId = this.timer.projectId;
      timeEntry.attributes.taskId = this.timer.taskId;
      timeEntry.attributes.userId = this.timer.userId;
      // @TODO this probably needs to be mapped
      //console.log('timeEntry', timeEntry);
      const newTimeEntry = await timeEntryModule.save(timeEntry.id);
      //const newTimeEntry = await timerModule.save(timeEntry.id);
      //console.log('timeEntry', newTimeEntry);
      this.message = null;
      this.logging = false;
      await timerModule.delete(String(this.timer.id));
    } catch (e) {
      console.error('Could not save timer', e);
      this.message = 'Could not save timer.';
      this.log('save-failed', 0);
      this.logging = false;
      return;
    }
  }

  async deleteTimer(timerId: string): Promise<void> {
    this.deleting = true;
    try {
      await timerModule.delete(timerId);
    } catch (e) {
      console.error('Could not delete timer', e);
      this.message = 'Could not delete timer.';
      this.log('delete-failed', 0);
    }
    this.deleting = false;
  }

  log(label: string, value: number): void {
    const lastValue =
      this.timer.log.length > 0
        ? value - this.timer.log[this.timer.log.length - 1].value
        : 0;
    this.timer.log.push({
      created: this.dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      label: label,
      value: value,
      difference: lastValue,
    });
  }

  roundUpToAny(roundToNumber: number, x: number): number {
    // const parseInt(roundToNumber);
    // const parseInt(x);
    //return (Math.ceil(n)%x === 0) ? Math.ceil(n) : Math.round((n + x / 2) / x) * x;
    //return (Math.ceil(roundToNumber)%x === 0) ? Math.ceil(roundToNumber) : Math.round((roundToNumber + x / 2) / x) * x;
    return Math.ceil(x / roundToNumber) * roundToNumber;
  }

  async saveTimer(): Promise<void> {
    this.saving = true;
    try {
      //console.log('saving timer', this.timer);
      const timer = await timerModule.save(String(this.timer.id));
      console.log('Saved and persisted', timer.id);
      this.$emit('saved');
    } catch (e) {
      console.error('Could not save timer', e);
      this.message = 'Could not save timer.';
      this.log('save-failed', 0);
    }
    this.saving = false;
  }

  setMinutes(minutes: number): void {
    //this.timer.duration = minutes;
    this.time = minutes * 60 * 1000;
    this.updateTimeDisplay();
  }

  async showProjectDialog(id: string): Promise<void> {
    this.projectId = id;
    if (id === 'new') {
      await projectModule.loadById('new');
      const project = await projectModule.save('new');
      this.projectId = project.id;
    }
    this.projectDialogShown = true;
  }

  startTimer(started?: Date): void {
    this.timer.running = true;
    this.timer.started = started === undefined ? new Date() : started;
    this.log('started', this.timer.started.valueOf());
    const interval = 1000;
    const expected = Date.now() + interval;
    this.time = this.timer.time; // for updating timeDisplay
    this.timeout = setTimeout(
      () => this.updateTimer(expected, interval),
      interval
    );
  }

  stopTimer(): void {
    this.timer.running = false;
    this.timer.stopped = new Date();
    this.log('stopped', this.timer.stopped.valueOf());
    clearTimeout(this.timeout);

    // For 100% accuracy, regardless of what this.timer.time is we calculate a new time based on start and stop time
    const newTime = this.timer.stopped.valueOf() - this.timer.started.valueOf();

    this.timer.time += newTime;
    this.updateTimeDisplay();
    //this.log('added', newTime);
  }

  toggleTimer(): void {
    if (this.timer.running) {
      this.stopTimer();
    } else {
      this.startTimer();
    }
  }

  updateDescriptionFromTemplate(): void {
    this.timer.description = String(this.selectedTemplate);
  }

  updateTimeDisplay(): void {
    this.timer.timeDisplay = msToTimeDisplay(this.time);
    //console.log(msToTimeDisplay(this.time));
  }

  updateTimeFromTimeDisplay(): void {
    this.timer.time = timeDisplayToMs(this.timer.timeDisplay);
    //console.log('change', this.timer.time);
  }

  updateTimer(expected: number, interval: number): void {
    if (!this.timer.running) {
      return;
    }

    const dt = Date.now() - expected; // the drift (positive for overshooting)
    //console.log('Drift:', dt + 'ms');
    if (dt > interval) {
      // something really bad happened. Maybe the browser (tab) was inactive?
      // possibly special handling to avoid futile "catch up" run
    }
    expected += interval;

    // calculate the new interval accounting for time drift
    const newInterval = Math.max(0, interval - dt);

    // calculate the ms time based on started date to ensure accuracy
    this.time = Date.now() - this.timer.started.valueOf() + this.timer.time;

    this.updateTimeDisplay();

    // create our new timeout in about 1 seconds taking drift into account
    this.timeout = setTimeout(
      () => this.updateTimer(expected, interval),
      newInterval
    );
  }
}
