





































import Vue from 'vue';
import {orderBy} from 'lodash';
import TimersTimer from '@/components/timers/TimersTimer.vue';
import {mdiPlus} from '@mdi/js';
import Component from 'vue-class-component';
import {
  clientModule,
  projectModule,
  taskModule,
  timeEntryModule,
  timerModule,
} from '@/store/modules';
import {Snackbar, TimeEntryAttributes, TimerResource} from '@/interfaces';
import PageTitle from '@/components/PageTitle.vue';

@Component({
  components: {TimersTimer, PageTitle},
})
export default class Timers extends Vue {
  creating = false;
  loading = false;
  newTimerIcon = mdiPlus;
  snackbar: Snackbar = {visible: false, message: ''};

  get listing(): boolean {
    return timerModule.listing;
  }

  get timers(): TimerResource[] {
    // filter out all new timers
    const x = orderBy(
      timerModule.list.filter(item => String(item.id).substr(0, 3) !== 'new'),
      'client.name'
    );
    console.log(x);
    return x;
  }

  async created(): Promise<void> {
    //this.loading = true;
    await timerModule.loadMulti();
    await Promise.all([
      clientModule.loadMulti(),
      projectModule.loadMulti(),
      taskModule.loadMulti(),
    ]);
    //this.loading = false;
  }

  async newTimer(): Promise<void> {
    this.creating = true;
    await timerModule.loadById('new');
    await timerModule.save('new');
    this.creating = false;
  }

  saved(): void {
    this.snackbar.visible = true;
    this.snackbar.message = 'Your timer has been saved.';
  }

  logged(): void {
    this.snackbar.visible = true;
    this.snackbar.message = 'Your time has been logged.';
  }

  deleted(): void {
    this.snackbar.visible = true;
    this.snackbar.message = 'Your timer has been deleted.';
  }
}
