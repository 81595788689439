export * from './vuetify';
export * from './json-api';
export * from './requests';
export * from './resources';

export interface EditableFieldSaveEvent {
  id: string;
  value: string;
  originalValue: string;
}
