import {ResourceType} from '@/enums';

export * from './ActionResource';
export * from './AuthResource';
export * from './ClientResource';
export * from './InvoiceResource';
export * from './ProjectResource';
export * from './ReportResource';
export * from './TaskResource';
export * from './TimeEntryResource';
export * from './TimerResource';
export * from './UserResource';

export interface DataResource<T> {
  type: ResourceType;
  id: string;
  attributes: T;
}

export interface DataAttributes {
  id: number;
  created: Date;
  modified: Date;
}
