































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import {timeEntryModule} from '@/store/modules';
import {TimeEntryAttributes} from '@/interfaces';
import {minutesToHours} from '@/filters';
import {VueShowdown} from 'vue-showdown/dist/vue-showdown.esm';

@Component({components: {VueShowdown}})
export default class LastTimeEntries extends Vue {
  @Prop({default: '1'}) private clientId!: string;
  @Prop({default: '-1'}) private projectId!: string;
  private expanded: string[] = [];
  private headersStaff = [
    {
      align: 'center',
      text: 'Date',
      value: 'date',
    },
    {
      text: 'Client',
      align: 'left',
      name: 'client',
      value: 'client.name',
    },
    {text: 'Project', value: 'project.name'},
    {
      text: 'Hours',
      value: 'duration',
    },
    {text: 'Task', value: 'task.name'},
    {text: '', value: 'data-table-expand'},
  ];
  private minutesToHours = minutesToHours;

  get listing(): boolean {
    return timeEntryModule.listing;
  }

  get timeEntries(): TimeEntryAttributes[] {
    return timeEntryModule.list
      .map(item => {
        return item.attributes;
      })
      .filter(
        item =>
          (String(item.projectId) === '-1' ||
            String(item.projectId) === String(this.projectId)) &&
          String(item.clientId) === String(this.clientId)
      )
      .slice(0, 3);
  }
  async created(): Promise<void> {
    console.log(this.clientId, 1, this.projectId);
    await this.update();
  }

  async update(): Promise<void> {
    await timeEntryModule.loadMulti({
      append: true,
      filters: {clientId: this.clientId, projectId: this.projectId},
      page: {limit: 20},
    });
  }

  @Watch('clientId')
  onClientIdUpdate() {
    this.update();
  }

  @Watch('projectId')
  onProjectIdUpdate() {
    this.update();
  }
}
