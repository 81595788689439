var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headersStaff,"items":_vm.timeEntries,"items-per-page":5,"expanded":_vm.expanded,"fixed-header":true,"single-expand":true,"loading":_vm.listing,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.date))])]}},{key:"item.client.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.client.name))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-5 pb-10",attrs:{"colspan":5}},[_c('VueShowdown',{attrs:{"markdown":item.description}})],1)]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.minutesToHours(item.duration))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }