








import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {initials} from '@/filters';

@Component
export default class ClientAvatar extends Vue {
  @Prop() clientName!: string;
  initials = initials;

  colorFromString(str: string): string {
    const colors: Record<string, string> = {
      a: 'red',
      b: 'pink',
      c: 'purple',
      d: 'deep-purple',
      e: 'indigo',
      f: 'blue',
      g: 'light-blue',
      h: 'cyan',
      i: 'teal',
      j: 'green',
      k: 'light-green',
      l: 'lime',
      m: 'yellow',
      n: 'amber',
      o: 'orange',
      p: 'deep-orange',
      q: 'brown',
      r: 'blue-grey',
      s: 'red',
      t: 'pink',
      u: 'deep-purple',
      v: 'indigo',
      w: 'cyan',
      x: 'teal',
      y: 'green',
      z: 'amber',
    };
    return colors[str.substr(0, 1).toLowerCase()];
  }
}
