import {ResourceType} from '@/enums';
import {SelectItem} from '@/interfaces';

export interface TimerResource {
  type: ResourceType.Timer;
  id: string;
  attributes: TimerAttributes;
}

export interface TimerAttributes {
  billable: boolean;
  clientId: number;
  // using a date object for 'date' makes things complicated since the date
  // object includes a time component which upsets the API and since we don't
  // need to manipulate the date a string is best (date picker works fine
  // with strings)
  date: string;
  description: string;
  durationExpense: number;
  id: number;
  log: TimerLog[];
  projectId: number;
  running: boolean;
  started: Date;
  stopped: Date;
  taskId: number;
  time: number;
  timeDisplay: string;
  userId: number;
  created: Date;
  modified: Date;
}

export interface TimerLog {
  created: string;
  difference: number;
  label: string;
  value: number;
}

export const timerTemplates: SelectItem[] = [
  {
    text: 'Adviceon: New Canfin Rep Site',
    value:
      'Create new site for Canfin rep:\n' +
      '- Added information to Google Spreadsheet.\n' +
      '- Created Advisor Platform configuration.\n' +
      '- Created Google Analytics account\n' +
      '- Created Google Search Console account\n' +
      '- Created Financium account.\n' +
      '- Created default starter page in Financium and submitted to compliance',
  },
  {
    text: 'Adviceon - Remove Advisor Platform Site',
    value:
      'Removed site XXXXXX.XXX:\n' +
      '- Removed Advisor Platform configuration\n' +
      '- Removed web server configuration\n' +
      '- Disabled SSL renewal cron job\n' +
      '- Disabled Financium account\n' +
      '- Removed Google Analytics account',
  },
];
